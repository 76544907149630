import React from 'react';




const Terms = ({ containerStyle, headingStyle, paragraphStyle }) => {
  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Digital Saccoss Privacy Policy</h1>

      <p>
        Effective Date: 31 July 2023
      </p>

      <p>
        At Digital Saccoss, we are dedicated to maintaining the privacy and security of your personal information.
        This Privacy Policy outlines how we collect, use, disclose, and protect your data when you use our mobile application.
      </p>

      <section>
        <h2>1. Information We Collect</h2>

        <p>
          We may collect the following types of information when you use Digital Saccoss mobile app:
        </p>

        <h3>1.1. Personal Information</h3>
        <p>
          We may collect personally identifiable information, such as your name, email address, phone number, and any other information you voluntarily provide.
        </p>

        <h3>1.2. Non-Personal Information</h3>
        <p>
          We may collect non-personal information, such as device information, operating system, and usage data, which is automatically collected when you interact with our app.
        </p>
      </section>

      <section>
        <h2>2. How We Use Your Information</h2>

        <p>
          We may use the collected information for the following purposes:
        </p>

        <h3>2.1. App Functionality</h3>
        <p>
          To provide you with access to and use of our mobile app's features and services.
        </p>

        <h3>2.2. Personalization</h3>
        <p>
          To personalize your experience and display content tailored to your preferences.
        </p>

        <h3>2.3. Communication</h3>
        <p>
          To communicate with you regarding updates, notifications, and promotional materials related to our services.
        </p>

        <h3>2.4. Analytics</h3>
        <p>
          To analyze usage patterns, improve our app's functionality, and enhance user experience.
        </p>
      </section>

      <section>
        <h2>3. Information Sharing and Disclosure</h2>

        <p>
          We may share your information under the following circumstances:
        </p>

        <h3>3.1. Service Providers</h3>
        <p>
          We may engage trusted third-party service providers to assist us in operating our app and providing services to you.
          These providers are obligated to maintain the confidentiality of your information.
        </p>

        <h3>3.2. Legal Compliance</h3>
        <p>
          We may disclose your information if required by law or in response to valid requests from government authorities.
        </p>
      </section>

      <section>
        <h2>4. Data Security</h2>

        <p>
          We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction.
          However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section>
        <h2>5. Third-Party Links</h2>

        <p>
          Our app may contain links to external websites or services operated by third parties.
          We are not responsible for the privacy practices or content of these third-party sites.
          Please review their privacy policies before interacting with them.
        </p>
      </section>

      <section>
        <h2>6. Children's Privacy</h2>

        <p>
          Our app is not intended for use by individuals under the age of 18.
          We do not knowingly collect personal information from children.
          If you believe a child has provided us with personal information, please contact us at the email address provided below, and we will promptly remove the information.
        </p>
      </section>

      <section>
        <h2>7. Changes to the Privacy Policy</h2>

        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.
          The updated policy will be effective upon posting on our website, and we encourage you to review it periodically.
        </p>
      </section>

      <section>
        <h2>8. Contact Us</h2>

        <p>
          If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at:
        </p>

        <ul>
          <li>Email: dsaccos.lti@gmail.com</li>
          <li>Website: <a href="https://www.lycustechnologies.com" target="_blank" rel="noopener noreferrer">https://www.lycustechnologies.com</a></li>
          <li>Phone: +255 655 670 670</li>
        </ul>

        <p>
          By using Digital Saccoss, you consent to the terms of this Privacy Policy.
        </p>

        <p>
          Thank you for using Digital Saccoss, and we assure you of our commitment to safeguarding your privacy.
        </p>

        <p>
        Digital Saccoss Team
        </p>
      </section>
    </div>
  );
};
  
  export default Terms;
