import React from 'react';
import Terms from './Terms_policies';

const PrivacyPolicy = () => {
  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '10px',
    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    marginTop: '30px',
  };

  const paragraphStyle = {
    lineHeight: '1.6',
  };

  return (
    <Terms containerStyle={containerStyle} headingStyle={headingStyle} paragraphStyle={paragraphStyle} />
  );
};

export default PrivacyPolicy;


